import { toast } from "react-toastify";
import APIList from "../../../../../api";
import { isEmptyNullUndefined } from "../../../../utils/utils";
import { RiErrorWarningFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import SideDrawer from "../../../../common/sideDrawer";
import { BASE_URL } from "../../../../../api/apiServices";
import axios from "axios";
import { saveAs } from 'file-saver';

const parse = require("html-react-parser");

const CompHistory = () => {

    const employeeDetails = useSelector((state) => state?.empData?.empData);
    const userToken = useSelector((state) => state?.user?.userToken);

    const [letterData, setletterData] = useState(null);
    const [dataLoader, setDataLoader] = useState(false);
    const [isOpenDrawerForIbtn, setIsOpenDrawerForIbtn] = useState(false);
    const [sendLoader, setSendLoader] = useState(false);

    const [activeTab, setActiveTab] = useState("Compensation Letter");

    useEffect(() => {

        if(!isEmptyNullUndefined(employeeDetails?.id)){
            getLetter();
            getLetterPdf();
        }
        
    }, [employeeDetails])   
    
    console.log("letterData",letterData)

    const getLetter = () => {
        setDataLoader(false);
        APIList.compHistoryLetter({
            companyId: employeeDetails?.company?.id,
            employeeId: employeeDetails?.id,
            isManagerView: false
        })
        .then((res) => {
            // setTotalCompData(res?.data?.data);
            setletterData(res?.data[0]);
            setDataLoader(false);
        }).catch((err) => {
            toast.error(
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{err?.title}
                </div>
            )
            setDataLoader(false);
        })
    }

    const [pdfUrl, setPdfUrl] = useState("");
    const [getPDFLoader, setGetPDFLoader] = useState(false);

    const getLetterPdf = () => {
        setGetPDFLoader(true);

        axios.post(`${BASE_URL}/services/benevolvemedibuddy/api/letter/generate-pdf`,
            {
                companyId: employeeDetails?.company?.id,
                employeeId: employeeDetails?.id,
                isManagerView: false
        },
            {headers: {
                'Authorization': `Bearer ${userToken?.id_token}`,
                'Content-Type': 'application/json',
              },  'responseType': 'arraybuffer'      
            }).then((response) => 
            {
                //  const blob = new Blob([response?.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
               
              // Trigger the download
            //   saveAs(blob, `${e.target.value.reportName}.pdf`);


              // Create a Blob from the byte array
                const blob = new Blob([response?.data], { type: 'application/pdf' });

                // Create a URL for the Blob
                const url = URL.createObjectURL(blob);

                setPdfUrl(url);

                // // Create an anchor element and trigger a download
                // const link = document.createElement('a');
                // link.href = url;
                // link.download = `${employeeDetails?.name}.pdf`; // Specify the name of the downloaded file
                // document.body.appendChild(link);
                // link.click();

                // // Clean up by removing the anchor element and revoking the object URL
                // document.body.removeChild(link);
                // URL.revokeObjectURL(url);

                setGetPDFLoader(false);
                
            })
            .catch((err) => {
                     toast.error(
                    <div style={{display:"flex", flexDirection:"row"}}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{err?.title}
                    </div>
                );
                setGetPDFLoader(false);
            });    
    }

    const downloadSinleLetter = () => {
        setSendLoader(true);

        axios.post(`${BASE_URL}/services/benevolvemedibuddy/api/letter/generate-pdf`,
            {
                companyId: employeeDetails?.company?.id,
                employeeId: employeeDetails?.id,
                isManagerView: false
        },
            {headers: {
                'Authorization': `Bearer ${userToken?.id_token}`,
                'Content-Type': 'application/json',
              },  'responseType': 'arraybuffer'      
            }).then((response) => 
            {
                //  const blob = new Blob([response?.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
               
              // Trigger the download
            //   saveAs(blob, `${e.target.value.reportName}.pdf`);


              // Create a Blob from the byte array
                const blob = new Blob([response?.data], { type: 'application/pdf' });

                // Create a URL for the Blob
                const url = URL.createObjectURL(blob);

                setPdfUrl(url);

                // Create an anchor element and trigger a download
                const link = document.createElement('a');
                link.href = url;
                link.download = `${employeeDetails?.name}.pdf`; // Specify the name of the downloaded file
                document.body.appendChild(link);
                link.click();

                // Clean up by removing the anchor element and revoking the object URL
                document.body.removeChild(link);
                URL.revokeObjectURL(url);

              setSendLoader(false);
                
            })
            .catch((err) => {
                     toast.error(
                    <div style={{display:"flex", flexDirection:"row"}}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{err?.title}
                    </div>
                );
                setSendLoader(false);
            });    
    }

    return(
        <div className="compenssation-history">
            <div className="tabs-outer">
            <div className="r-f-tabs">
                    
                {/* <div className={`tab ${activeTab === "Compensation Profile" ? "active" : ""}`}
                 onClick={() => setActiveTab("Compensation Profile")}
                 > Compensation Profile </div> */}
            
                <div className={`tab ${activeTab === "Compensation Letter" ? "active" : ""}`} onClick={() => setActiveTab("Compensation Letter")}> Compensation Letter </div>
            
            </div>
            <div className="i-btn" onClick={() => setIsOpenDrawerForIbtn(true)}>i</div> 
            </div>

            <div className="letter-block">

                {/* {

                    dataLoader ? 
                    <CircularProgress />
                    :
                    !isEmptyNullUndefined(letterData) && activeTab === "Compensation Letter" &&
                    parse(letterData?.body)

                } */}

            {
                getPDFLoader ? 
                    (
                        <CircularProgress />
                    )
                    :
                    (
                        pdfUrl &&
                        <div className="pdf-viewer">
                            <iframe
                                src={pdfUrl}
                                width="100%"
                                height="600px"
                                title="PDF Viewer"
                            />
                        </div>
                    )
            }

            </div>

            {
                !isEmptyNullUndefined(letterData) &&
                <div className="btn-outer">

                    {
                        sendLoader ? 
                        <CircularProgress />
                        :
                        <button className="download-letter-btn" onClick={downloadSinleLetter}>
                            Download Letter
                        </button>
                    }
                    
                </div>
            }

        {
            isOpenDrawerForIbtn && 
            <SideDrawer isOpen={isOpenDrawerForIbtn} setIsOpen={setIsOpenDrawerForIbtn} >
              <div>Please provide the content</div>
            </SideDrawer>
        }

        </div>
    )
}

export default CompHistory;