import { Accordion, AccordionDetails, AccordionSummary, Button, FormControl, InputLabel, MenuItem, Popover, Select, Stack, TextField, Checkbox } from "@mui/material";
import { BsChevronDown } from "react-icons/bs";
import { useState } from "react";
import { useSelector } from "react-redux";
import { accordianSVGstyle } from "../../../../utils/utils";
import GoalSummaryIcon from "../../../../../assets/images/goalView/goalSummary.svg";
import MilestoneSummaryIcon from "../../../../../assets/images/goalView/milestoneSummary.svg";
import SliderIcon from "../../../../../assets/images/goalView/slider.svg";
import UserProfile1Icon from "../../../../../assets/images/goalView/userProfile1.svg";
import UserProfile2Icon from "../../../../../assets/images/goalView/userProfile2.svg";
import CommentSendBtnIcon from "../../../../../assets/images/goalView/commentSendBtn.svg";
import QuIcon from "../../../../../assets/images/goalView/quIcon.svg";
import ExpandAnyGoalBgIcon from "../../../../../assets/images/goalView/expandAnyGoalBg.svg";
import ExpandAnyGoalIcon from "../../../../../assets/images/goalView/expandAnyGoal.svg";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import StatusHistory from "./StatusHistory";

const EmployeeGoalView = () => {
    const [selectedYearDDReport, setSelectedYearDDReport] = useState('Q1 2024');
    const [openExpandedCard, setOpenExpandedCard] = useState(false);
    const [checked, setChecked] = useState(false);

    const handleYearDDChange = (event) => {
        setSelectedYearDDReport(event.target.value);
    };

    const handleOpenExpandedCard = () => {
        setOpenExpandedCard(!openExpandedCard)
    }

    const handleCheckboxChange = (event) => {
        setChecked(event.target.checked);
    };

    const goalYearList = [
        { id: '1', goalYearValue: 'Q1 2024' },
        { id: '2', goalYearValue: 'Q2 2024' },
        { id: '3', goalYearValue: 'Q3 2024' }
    ];

    return (
        <div className="emp-goals-page">
            {/*----- Year Drop Down -----*/}
            <div className="year-dropDown-div">
                <FormControl className="year-dropDown">
                    <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedYearDDReport}
                        label="Q2 2024"
                        onChange={handleYearDDChange}
                        renderValue={(selected) => (
                            <span>{selected?.goalYearValue || "Q2 2024"}</span>
                        )}
                    >
                        {goalYearList?.map((goalYear) => {
                            return (
                                <MenuItem value={goalYear}>{goalYear?.goalYearValue}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </div>

            {/*----- Chart layout -----*/}
            <div className="outer-chart-layout">
                {/*----- Goal Summary -----*/}
                <div className="column-layout">
                    <p className="goalMilestoneSummary">Goal Summary</p>

                    <div className="chart-column">
                        <img
                            src={GoalSummaryIcon}
                            alt="GoalSummaryIcon"
                        />

                        <div className="d-block-w100">
                            <div className="sub-outer-chart">
                                <div className="sub-outer-column">
                                    <div className="d-flex">
                                        <div className="status-card status-green" />
                                        <p className="sub-col-value">4 <span className="sub-col-percent-value">(40%)</span></p>
                                    </div>
                                    <p className="sub-col-status">Completed</p>
                                </div>
                                <div className="sub-outer-column">
                                    <div className="d-flex">
                                        <div className="status-card status-yellow" />
                                        <p className="sub-col-value">30 <span className="sub-col-percent-value">(30%)</span></p>
                                    </div>
                                    <p className="sub-col-status">Inprogress</p>
                                </div>
                            </div>

                            <div className="sub-outer-chart mt-18">
                                <div className="sub-outer-column">
                                    <div className="d-flex">
                                        <div className="status-card status-blue" />
                                        <p className="sub-col-value">2 <span className="sub-col-percent-value">(20%)</span></p>
                                    </div>
                                    <p className="sub-col-status">Not Started</p>
                                </div>
                                <div className="sub-outer-column">
                                    <div className="d-flex">
                                        <div className="status-card status-red" />
                                        <p className="sub-col-value">1 <span className="sub-col-percent-value">(10%)</span></p>
                                    </div>
                                    <p className="sub-col-status">Delayed</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*----- Vertical Border -----*/}
                <div className="verticalBorder"></div>

                {/*----- Milestone Summary -----*/}
                <div className="column-layout">
                    <p className="goalMilestoneSummary">Milestone Summary</p>

                    <div className="chart-column">
                        <img
                            src={MilestoneSummaryIcon}
                            alt="MilestoneSummaryIcon"
                        />

                        <div className="d-block-w100">
                            <div className="sub-outer-chart">
                                <div className="sub-outer-column">
                                    <div className="d-flex">
                                        <div className="status-card status-green" />
                                        <p className="sub-col-value">8 <span className="sub-col-percent-value">(40%)</span></p>
                                    </div>
                                    <p className="sub-col-status">Completed</p>
                                </div>
                                <div className="sub-outer-column">
                                    <div className="d-flex">
                                        <div className="status-card status-yellow" />
                                        <p className="sub-col-value">6 <span className="sub-col-percent-value">(30%)</span></p>
                                    </div>
                                    <p className="sub-col-status">Inprogress</p>
                                </div>
                            </div>

                            <div className="sub-outer-chart mt-18">
                                <div className="sub-outer-column">
                                    <div className="d-flex">
                                        <div className="status-card status-blue" />
                                        <p className="sub-col-value">4 <span className="sub-col-percent-value">(20%)</span></p>
                                    </div>
                                    <p className="sub-col-status">Not Started</p>
                                </div>
                                <div className="sub-outer-column">
                                    <div className="d-flex">
                                        <div className="status-card status-red" />
                                        <p className="sub-col-value">2 <span className="sub-col-percent-value">(10%)</span></p>
                                    </div>
                                    <p className="sub-col-status">Delayed</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="goals-tab-btn-div">
                {/*----- Approved goals & Goals under review & Goals Under Draft Tab -----*/}
                <div className="goals-tab">
                    <div>
                        <div className="tab-d-flex">
                            <div className={`tab active`} >Approved goals<span>{"03"}</span></div>
                            <div className={`tab`}>Goals under review<span>{"02"}</span></div>
                            <div className={`tab`}>Goals Under Draft<span>{"02"}</span></div>
                        </div>

                        {/*----- All and Selected Count Btn -----*/}
                        <div className="all-selected-count-border">
                            <Button className="all-select-btn">
                                All
                            </Button>
                            <Button className="all-select-btn active-btn">
                                Selected (1)
                            </Button>
                        </div>
                    </div>
                    <div className="qu-icon-div">
                        <img
                            src={QuIcon}
                            alt="QuIcon"
                            height={17}
                            width={17}
                        />
                    </div>
                </div>


                <div className="submit-Goals-div">
                    <Button variant="outlined" className="submit-Goals-btn">
                        Submit Goals
                    </Button>
                </div>
            </div>

            {/*----- Edit & Comment Layout -----*/}
            <div className="outer-edit-comments-layout">
                {/*----- Edit Layout -----*/}
                <div className="edit-card-layout">
                    {/*----- 1. Event Management -----*/}
                    <Stack sx={{
                        "& .MuiAccordion-rounded": {
                            borderRadius: "10px !important",
                            boxShadow: "none",
                            backgroundColor: "#FFFFFF",
                        }
                    }}>

                        <Accordion expanded={openExpandedCard} onChange={() => handleOpenExpandedCard()} data-value={"stats"}>
                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header"
                                sx={{
                                    width: "100%",
                                    fontSize: "1.25rem",
                                    fontWeight: "600",
                                    "& .MuiAccordionSummary-content": {
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "space-between",
                                    },
                                }}
                            >
                                <div>
                                    <div className="d-flex">
                                        <Checkbox
                                            checked={checked}
                                            onChange={handleCheckboxChange}
                                            color="primary"
                                            sx={{
                                                '&.MuiButtonBase-root': {
                                                    padding: 0,
                                                },
                                            }}
                                        />
                                        <p className="accordian-heading">1. Event Management</p>
                                        <div className="slider-div">
                                            <p className="accordian-slider-persent">50%</p>
                                            <div className="slider" />
                                        </div>
                                    </div>
                                    <p className="accordian-end-date">End date : 30 September 2024</p>
                                </div>

                                <div className="d-flex">
                                    <Button variant="outlined" className="replicate-btn">
                                        Replicate
                                    </Button>

                                    <Button variant="contained" className="edit-btn">
                                        Edit
                                    </Button>

                                    <BsChevronDown style={{ ...accordianSVGstyle, transform: !openExpandedCard ? "rotate(0deg)" : "rotate(180deg)" }} />
                                </div>
                            </AccordionSummary>

                            <AccordionDetails>
                                <div>
                                    <div className="horizontal-border" />
                                    <div className="details-div">
                                        <p className="details-title">Goal Description</p>
                                        <p className="details-description">In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available.</p>

                                        <div className="sub-container">
                                            {/*----- Add Stakeholders & Goal cycle -----*/}
                                            <div className="sub-item-col">
                                                <p className="details-title">Add Stakeholders</p>

                                                <div className="d-flex">
                                                    <div className="border-div"><p className="sub-container-description">Micheal William</p></div>
                                                    <div className="border-div"><p className="sub-container-description">Wallace Mason</p></div>
                                                </div>
                                            </div>

                                            <div className="sub-item-col">
                                                <p className="details-title">Goal cycle</p>
                                                <p className="details-description">Q1 Cycle 2024, Q2 Cycle 2024</p>
                                            </div>
                                        </div>

                                        {/*----- Start Date & Goal Weightage in % -----*/}
                                        <div className="sub-container">
                                            <div className="sub-item-col">
                                                <p className="details-title">Start Date</p>
                                                <p className="details-description">30 July 2024</p>
                                            </div>

                                            <div className="sub-item-col">
                                                <p className="details-title">Goal Weightage in %</p>
                                                <p className="details-description">40%</p>
                                            </div>
                                        </div>

                                        {/*----- Priority & Goal Tag -----*/}
                                        <div className="sub-container">
                                            <div className="sub-item-col">
                                                <p className="details-title">Priority</p>
                                                <p className="details-description red-description">High</p>
                                            </div>

                                            <div className="sub-item-col">
                                                <p className="details-title">Goal Tag</p>
                                                <p className="details-description">Profitability</p>
                                            </div>
                                        </div>

                                        <p className="details-title mt-20">Path to Green</p>
                                        <p className="details-description">In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document.</p>


                                        <p className="details-title mt-20">Updates on this Goal</p>
                                        <p className="details-description">In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to... View More</p>


                                        {/*----- Milestone 1 -----*/}
                                        <div className="sub-status-div">
                                            <p className="sub-status-title">Milestone 1</p>
                                            <p className="completed-status">Completed</p>
                                            <div className="status-horizontal-border" />
                                            <BsChevronDown style={{ ...accordianSVGstyle, transform: !openExpandedCard ? "rotate(0deg)" : "rotate(180deg)" }} />
                                        </div>

                                        <div className="status-col-div">
                                            <div className="status-col">
                                                <div className="status-vertical-border" />
                                            </div>
                                            <div className="status-col">
                                                {/*----- Milestone Details & Milestone Completion -----*/}
                                                <div className="sub-container">
                                                    <div className="sub-item-col">
                                                        <p className="details-title">Milestone Details</p>
                                                        <p className="details-description">E-Auction as per policy</p>
                                                    </div>

                                                    <div className="sub-item-col">
                                                        <p className="details-title">Milestone Completion</p>
                                                        <p className="details-description">50%</p>
                                                    </div>
                                                </div>

                                                {/*----- Start Date & End Date -----*/}
                                                <div className="sub-container">
                                                    <div className="sub-item-col">
                                                        <p className="details-title">Start Date</p>
                                                        <p className="details-description">05 August 2024</p>
                                                    </div>

                                                    <div className="sub-item-col">
                                                        <p className="details-title">End Date</p>
                                                        <p className="details-description">15 August 2024</p>
                                                    </div>
                                                </div>

                                                {/*----- Milestone Deadline -----*/}
                                                <div className="sub-container">
                                                    <div className="sub-item-col">
                                                        <p className="details-title">Milestone Deadline</p>
                                                        <p className="details-description">08 August 2024</p>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        {/*----- Milestone 2 -----*/}
                                        <div className="sub-status-div">
                                            <p className="sub-status-title">Milestone 2</p>
                                            <p className="delayed-status">Delayed</p>
                                            <div className="status-horizontal-border" />
                                            <BsChevronDown style={{ ...accordianSVGstyle, transform: !openExpandedCard ? "rotate(0deg)" : "rotate(180deg)" }} />
                                        </div>


                                        {/*----- Milestone 3 -----*/}
                                        <div className="sub-status-div">
                                            <p className="sub-status-title">Milestone 3</p>
                                            <p className="inProgress-status">In Progress</p>
                                            <div className="status-horizontal-border" />
                                            <BsChevronDown style={{ ...accordianSVGstyle, transform: !openExpandedCard ? "rotate(0deg)" : "rotate(180deg)" }} />
                                        </div>
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </Stack>

                    {/*----- 2. Capex / POS / RnR -----*/}
                    <Stack sx={{
                        "& .MuiAccordion-rounded": {
                            borderRadius: "10px !important",
                            boxShadow: "none",
                            backgroundColor: "#FFFFFF",
                            marginTop: "16px"
                        }
                    }}>

                        <Accordion expanded={openExpandedCard} onChange={() => handleOpenExpandedCard()} data-value={"stats"}>
                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header"
                                sx={{
                                    width: "100%",
                                    fontSize: "1.25rem",
                                    fontWeight: "600",
                                    "& .MuiAccordionSummary-content": {
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }
                                }}
                            >
                                <div>
                                    <div className="d-flex">
                                        <Checkbox
                                            checked={checked}
                                            onChange={handleCheckboxChange}
                                            color="primary"
                                            sx={{
                                                '&.MuiButtonBase-root': {
                                                    padding: 0,
                                                },
                                            }}
                                        />
                                        <p className="accordian-heading">2. Capex / POS / RnR</p>
                                        <p className="slider-status status-gray-color">Not Started</p>
                                        <div className="slider-div">
                                            <p className="accordian-slider-persent">50%</p>
                                            <div className="slider" />
                                        </div>
                                    </div>
                                    <p className="accordian-end-date">End date : 30 September 2024</p>
                                </div>

                                <div className="d-flex">
                                    <Button variant="contained" className="edit-btn">
                                        Edit
                                    </Button>

                                    <BsChevronDown style={{ ...accordianSVGstyle, transform: !openExpandedCard ? "rotate(0deg)" : "rotate(180deg)" }} />
                                </div>
                            </AccordionSummary>

                            <AccordionDetails sx={{ fontSize: "1rem" }}>
                                <div>
                                    <p>Capex / POS / RnR Details</p>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </Stack>


                    {/*----- 3. Capex / POS / RnR -----*/}
                    <Stack sx={{
                        "& .MuiAccordion-rounded": {
                            borderRadius: "10px !important",
                            boxShadow: "none",
                            backgroundColor: "#FFFFFF",
                            marginTop: "16px",
                        }
                    }}>

                        <Accordion expanded={openExpandedCard} onChange={() => handleOpenExpandedCard()} data-value={"stats"}>
                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header"
                                sx={{
                                    width: "100%",
                                    fontSize: "1.25rem",
                                    fontWeight: "600",
                                    "& .MuiAccordionSummary-content": {
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }
                                }}
                            >
                                <div>
                                    <div className="d-flex">
                                        <Checkbox
                                            checked={checked}
                                            onChange={handleCheckboxChange}
                                            color="primary"
                                            sx={{
                                                '&.MuiButtonBase-root': {
                                                    padding: 0,
                                                },
                                            }}
                                        />
                                        <p className="accordian-heading">3. Capex / POS / RnR</p>
                                        <p className="slider-status status-green-color">On Track</p>
                                        <div className="slider-div">
                                            <p className="accordian-slider-persent">50%</p>
                                            <div className="slider" />
                                        </div>
                                    </div>
                                    <p className="accordian-end-date">End date : 30 September 2024</p>
                                </div>

                                <div className="d-flex">
                                    <Button variant="contained" className="edit-btn">
                                        Edit
                                    </Button>

                                    <BsChevronDown style={{ ...accordianSVGstyle, transform: !openExpandedCard ? "rotate(0deg)" : "rotate(180deg)" }} />
                                </div>
                            </AccordionSummary>

                            <AccordionDetails sx={{ fontSize: "1rem" }}>
                                <div>
                                    <p>Capex / POS / RnR Details</p>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </Stack>

                    {/* <StatusHistory /> */}
                </div>


                {/*----- Comment Layout -----*/}
                <div className="comments-card-layout">
                    <div className="d-flex">
                        <p className="comments-label">Comments</p>
                        <p className="comment-count">{"1"}</p>
                    </div>

                    {/*----- Comment Card 1 -----*/}
                    <div className="comment-user-card">
                        <div className="d-flex">
                            <div className="user-profile">
                                <img
                                    src={UserProfile1Icon}
                                    alt="UserProfile1Icon"
                                    height={40}
                                    width={40}
                                />
                            </div>
                            <div className="user-div">
                                <p className="userName">John Doe</p>
                                <p className="userPost">Manager</p>
                            </div>
                        </div>

                        <p className="user-comment">In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface.</p>

                        <p className="comment-date">03 Aug 2024, 12:46PM</p>
                    </div>

                    {/*----- Comment Card 2 -----*/}
                    <div className="comment-user-card">
                        <div className="d-flex">
                            <div className="user-profile">
                                <img
                                    src={UserProfile2Icon}
                                    alt="UserProfile2Icon"
                                    height={40}
                                    width={40}
                                />
                            </div>
                            <div className="user-div">
                                <p className="userName">Harry mustain</p>
                                <p className="userPost">You</p>
                            </div>
                        </div>

                        <p className="user-comment">In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to.</p>

                        <p className="comment-date">03 Aug 2024, 12:46PM</p>
                    </div>

                    {/*----- Comment Card 3 -----*/}
                    <div className="comment-user-card comment-card-bg">
                        <div className="d-flex">
                            <div className="user-profile">
                                <img
                                    src={UserProfile1Icon}
                                    alt="UserProfile1Icon"
                                    height={40}
                                    width={40}
                                />
                            </div>
                            <div className="user-div">
                                <p className="userName">John Doe</p>
                                <p className="userPost">Manager</p>
                            </div>
                        </div>

                        <p className="user-comment">In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available.</p>

                        <p className="comment-date">03 Aug 2024, 12:46PM</p>
                    </div>

                    {/*----- Expand any goal to view its comments. Card -----*/}
                    <div className="comment-user-card">
                        <div className="expandAnyGoal-Icon-div">
                            <img
                                src={ExpandAnyGoalBgIcon}
                                alt="ExpandAnyGoalBgIcon"
                                height={90}
                                width={90}
                                className="background-Icon"
                            />
                            <img
                                src={ExpandAnyGoalIcon}
                                alt="ExpandAnyGoalIcon"
                                height={73}
                                width={80}
                                className="expandAnyGoal-Icon"
                            />
                        </div>
                        <p className="expandAnyGoal">Expand any goal to view its comments.</p>
                    </div>

                    {/*----- Border -----*/}
                    <div className="comment-border" />

                    {/*----- Comment TextField -----*/}
                    <div className="comment-field-div">
                        <TextField
                            placeholder="Enter your comment..."
                            variant="outlined"
                            size="small"
                            fullWidth
                            // value={comment}
                            // onChange={handleCommentChange}
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                        border: "none",
                                    },
                                },
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            edge="end"
                                            color="primary"
                                        // onClick={() => {}}
                                        >
                                            <img
                                                src={CommentSendBtnIcon}
                                                alt="CommentSendBtnIcon"
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default EmployeeGoalView;