import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import PropTypes from 'prop-types';


const RatingTable = ({ data }) => {

  const groupedData = data.reduce((acc, currentValue) => {
    const { cycleName, ratingName, trendingRating } = currentValue;
    if (!acc[cycleName]) {
      acc[cycleName] = { cycleName, MBB: '', OKR: '' };
    }
    if (ratingName === 'MBB') {
      acc[cycleName].MBB = trendingRating;
    } else if (ratingName === 'OKR') {
      acc[cycleName].OKR = trendingRating;
    }
    return acc;
  }, {});

  return (
    <div className="accordian-data rating-table">
    <TableContainer component={Paper} className='rating-table' >
      <Table border={0}>
        <TableHead className="table-header">
          <TableRow style={{ background: '#f0f0f0' }}>
            <TableCell className="table-header" align='center'>Cycle Name</TableCell>
            <TableCell className="table-header" align='center' >MBB</TableCell>
            <TableCell className="table-header" align='center'>OKR</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.values(groupedData).map((row, index) => (
            <TableRow key={row.cycleName} style={{borderBottom: 'none' }} align='center'>
              <TableCell align='center' >{row.cycleName}</TableCell>
              <TableCell align='center'>{row.MBB}</TableCell>
              <TableCell align='center'>{row.OKR}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

    </div>
  );
};

RatingTable.propTypes = {
  data: PropTypes.array,
};

export default RatingTable;
