import React from "react";

function Goals() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
    >
      <path
        fill="#fff"
        d="M9.196 4.145A4.52 4.52 0 003.484 8.5 4.52 4.52 0 008 13.015a4.52 4.52 0 004.355-5.711.5.5 0 11.965-.265A5.521 5.521 0 018 14.015 5.522 5.522 0 012.485 8.5 5.522 5.522 0 019.46 3.18a.5.5 0 01-.265.965zm5.855 1.744a.5.5 0 00-.338.621A7.005 7.005 0 018 15.5c-3.86 0-7-3.14-7-7a7.008 7.008 0 018.99-6.713.5.5 0 00.284-.959A8.004 8.004 0 008 .5a7.948 7.948 0 00-5.657 2.343A7.948 7.948 0 000 8.5c0 2.137.832 4.146 2.343 5.657A7.948 7.948 0 008 16.5a7.948 7.948 0 005.657-2.343A7.948 7.948 0 0016 8.5c0-.773-.11-1.538-.328-2.274a.5.5 0 00-.621-.337zM8 6.5c.074 0 .148.004.22.012a.5.5 0 10.11-.994A3.003 3.003 0 005 8.5c0 1.654 1.346 3 3 3s3-1.346 3-3a.5.5 0 10-1 0c0 1.103-.897 2-2 2s-2-.897-2-2 .897-2 2-2zm2.553-1.26l.249-2.498a.5.5 0 01.144-.304L12.737.646a.5.5 0 01.852.315l.142 1.808 1.808.142a.5.5 0 01.315.852l-1.792 1.791a.5.5 0 01-.304.144l-2.498.25-2.906 2.906a.498.498 0 01-.708 0 .5.5 0 010-.708l2.907-2.906zm1.038-.33l1.89-.19.898-.897-1.152-.09a.5.5 0 01-.46-.46l-.09-1.152-.898.898-.188 1.89z"
      ></path>
    </svg>
  );
}

export default Goals;