import { Skeleton } from "@mui/material";

const TableSkel = () => {
    return(
        [...Array(10).keys()].map((x) => {
            return(
                <tr key={x}>
            <td>
            <div className="empDetaila">
                                <div className="empPic">
                                <Skeleton variant="circular" width={50} height={50} />
                                </div>
                                <div className="userInfo">
                                    <p className="name"><Skeleton variant="text" sx={{ fontSize: '1rem', width:"7rem"  }} /></p>
                                    <p className="designation"><Skeleton variant="text" sx={{ fontSize: '1rem', width:"3rem" }} /></p>
                                </div>
                            </div>
            </td>
            <td><p className="btns"><Skeleton variant="text" sx={{ fontSize: '1rem', width:"5rem"  }} /></p></td>
            <td><p className="btns"><Skeleton variant="text" sx={{ fontSize: '1rem', width:"5rem"  }} /></p></td>
            <td><p className="btns"><Skeleton variant="text" sx={{ fontSize: '1rem', width:"5rem"  }} /></p></td>
            <td><p className="btns"><Skeleton variant="text" sx={{ fontSize: '1rem', width:"5rem"  }} /></p></td>
            <td></td>
        </tr>
            )
            
        })
    )
};

export default TableSkel;