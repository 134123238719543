import { BrowserRouter, Route, Switch } from "react-router-dom";
import AppRouters from "./routes";
import { history } from "./store";
import DynamicSidebar from "./components/common/dynamicSidebar";
import { useEffect, useState } from "react";

function App() {

  const [isLoading, setLoading] = useState(true);

    function fakeRequest() {
        return new Promise((resolve) => setTimeout(() => resolve(), 2));
    }
    useEffect(() => {
        fakeRequest().then(() => {
            const el = document.querySelector('.siteLoadingTempholder');
            if (el) {
                el.remove();
                setLoading(!isLoading);
            }
        });
    }, [isLoading]);

    if (isLoading) {
        return null;
    }

  return (
    <div className="App">
       <BrowserRouter history={history}>
        <Switch>
          <DynamicSidebar>
            <Route component={AppRouters} />
          </DynamicSidebar>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
