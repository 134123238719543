import { useSelector } from "react-redux";
import APIList from "../../../../../api";
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Stack } from "@mui/material";
import ManagerFeedback from "../../../managerFeedBack";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { RiErrorWarningFill } from "react-icons/ri";
import ManFeedback from "../manFeedback";
import { BsChevronDown } from "react-icons/bs";
import { accordianSVGstyle, isEmptyNullUndefined } from "../../../../utils/utils";
import ManFeedbackPerformanceHistory from "../performanceHistoryApraisal";
import Trial from "../trial";
// import UserPerformanceHistory from "../performanceHistoryApraisal";
import { FiDownload } from "react-icons/fi";
import { BASE_URL } from "../../../../../api/apiServices";
import axios from "axios";

const PerformanceHistory = () => {

    const employeeDetails = useSelector((state) => state?.empData?.empData);
    const annualcycleData = useSelector((state) => state?.annualcycleReducer?.annualcycle[0]);
    const userToken = useSelector((state) => state?.user?.userToken);

    const [expanded, setExpanded] = useState(null);
    const [formData, setFormData] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const [isDownloadPdf, setIsDownloadPdf] = useState(false);

    useEffect(() => {
        getFormData();
    }, [])

    const handleGeneratePdf = async () => {
      setIsDownloadPdf(true);
      // axios.get(`${BASE_URL}/services/benevolvemedibuddy/api/assessment-response/feedback-pdf/0f218429-f9d0-4173-ad23-abce397471c9/17600704-ecb4-4e0d-b0e5-30832d09c2b5/ManagerFeedBack`,
          
      //     {headers: {
      //         'Authorization': `Bearer ${userToken?.id_token}`,
      //         'Content-Type': 'application/json',
      //       },  'responseType': 'arraybuffer'      
      //     })
      axios.get(`${BASE_URL}/services/benevolvemedibuddy/api/assessment-response/feedback-pdf/${employeeDetails?.id}/${annualcycleData?.id}/SelfAppraisal `,
          
          {headers: {
              'Authorization': `Bearer ${userToken?.id_token}`,
              'Content-Type': 'application/json',
            },  'responseType': 'arraybuffer'      
          })
          .then((response) => 
          {
            if(response?.data){
             // Create a Blob from the byte array
              const blob = new Blob([response?.data], { type: 'application/pdf' });

              // Create a URL for the Blob
              const url = URL.createObjectURL(blob);

              // Create an anchor element and trigger a download
              const link = document.createElement('a');
              link.href = url;
              link.download = `${employeeDetails?.name}.pdf`; // Specify the name of the downloaded file
              document.body.appendChild(link);
              link.click();

              // Clean up by removing the anchor element and revoking the object URL
              document.body.removeChild(link);
              URL.revokeObjectURL(url);
            }

          setIsDownloadPdf(false);
              
          })
          .catch((err) => {
            console.log("errrrr",err)
                   toast.error(
                  <div style={{display:"flex", flexDirection:"row"}}>
                  <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                  &nbsp;&nbsp;{err?.message}
                  </div>
              );
              setIsDownloadPdf(false);
          });    

    };
    

    const getFormData = async () => {
        setisLoading(true);
        try {
          const response = await APIList.getCloseLoopAssesmentData({
            employeeId: employeeDetails?.id,
          });
          if (response.status === 200) {
         
            setFormData(response.data);
          } else {
          }
        } catch (error) {
          
            toast.error(
              <div style={{ display: "flex", flexDirection: "row" }}>
                <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
                &nbsp;&nbsp;{error?.message}
              </div>
            );
          }
          
        setisLoading(false);
      };

      const handleChange = (index) => {
        if (expanded === index) {
          setExpanded(null);
        } else {
          setExpanded(index);
        }
      };

      const handleDownloadPdf = (e) => {
        e.stopPropagation();
        handleGeneratePdf();
      }

    return(
        <div className="performance-history">

          <div className="header-box">
            <span>Cycle Name</span>
            <span>OKR</span>
            <span>MBB</span>
            <span>MBSR Doc</span>
          </div>
            
            {
              isLoading ? 
              <CircularProgress />
              :
               !isEmptyNullUndefined(formData) ?  
              <div className="acc-wrapper">
                <Stack
                  sx={{
                    margin: "1rem 0px",
                    "& .MuiAccordion-rounded": {
                      border: "1px solid #dad2d2",
                      borderRadius: "10px",
                      boxShadow: "none",
                    },
                  }}
                >
                  <Accordion
                    expanded={expanded === `Tab-2`}
                    onChange={(e) => {
                      handleChange(`Tab-2`);
                      e.stopPropagation();
                    }}
                    data-value={`Tab-1`}
                  >
                    <AccordionSummary
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                      sx={{
                        width: "100%",
                        fontSize: "1.25rem",
                        fontWeight: "600",
                        "& .MuiAccordionSummary-content": {
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        },
                      }}
                    >
                      <p className="accordian-heading">
                        {formData?.annualReviewCycleName}
                      </p>
                      {
                        formData?.ratings?.map((rating) => {
                          return(
                            <p className="accordian-heading" style={{fontWeight:"400", color:"var(--tertiary)", fontSize:"1rem"}}>
                              {rating?.rating}
                            </p>
                          )
                        })
                      }
                      
                      {
                        isDownloadPdf ? 
                        <CircularProgress />
                        :
                        <p className="download-btn-for-performance-history" onClick={handleDownloadPdf} >
                          <FiDownload />
                        </p>
                      }
                      
                      <BsChevronDown
                        style={{
                          ...accordianSVGstyle,
                          transform:
                          expanded !== `Tab-2`
                              ? "rotate(0deg)"
                              : "rotate(180deg)",
                        }}
                      />
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        fontSize: "1rem",
                      }}
                    >
                        <Trial sections={formData} isDownloadPdf={isDownloadPdf} setIsDownloadPdf={setIsDownloadPdf} />
                    </AccordionDetails>
                  </Accordion>
                </Stack>
              </div>
              :
              <div className="no-data" style={{color:"var(--primary)"}}>No data found</div>
            }
            

        </div>
    )
}

export default PerformanceHistory;